:root {
  --primary-blue: #4A90E2;
  --primary-blue-dark: #3A7BC8;
  --primary-blue-light: #6AA9E9;
  --accent-yellow: #FFD037;
  --sky-blue: #87CEEB;
}

.hire-with-us-page {
  margin: 0 auto;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  color: #2D3748;
  width: 100%;
  text-align: center;
}

.hire-with-us-banner {
  position: relative;
  height: 600px;
  margin: 40px 0;
  overflow: hidden;
  background: linear-gradient(135deg, var(--primary-blue) 0%, #f4fcff 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
}

.hire-banner-image {
  max-width: 90%;
  height: auto;
  margin-bottom: 2rem;
  filter: drop-shadow(0 10px 15px rgba(0, 0, 0, 0.2));
  animation: rise 1.5s ease-out;
}

@keyframes rise {
  0% { transform: translateY(50px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

.hire-banner-subtitle {
  font-size: 2rem;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 2rem;
  letter-spacing: 1px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: fadeIn 1s ease-out 0.5s forwards;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@media (max-width: 768px) {
  .hire-with-us-banner {
    height: 500px;
  }

  .hire-banner-image {
    max-width: 80%;
  }

  .hire-banner-subtitle {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .hire-with-us-banner {
    height: 400px;
  }

  .hire-banner-image {
    max-width: 90%;
  }

  .hire-banner-subtitle {
    font-size: 1.2rem;
  }
}
section {
  margin: 80px 0;
  padding: 60px;
  background: white;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInSection 0.8s ease-out forwards;
}

@keyframes fadeInSection {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

section:hover {
  transform: translateY(-5px);
  /* box-shadow: 0 10px 20px rgba(74, 144, 226, 0.2); */
}

h2 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 24px;
  color: var(--primary-blue-dark);
}

p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #4A5568;
  margin: 0;
}

section.how-it-works {
  width: 70%;
  align-items: center;
  margin-left: 15%;
}

.how-it-works ol {
  counter-reset: steps;
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
}

.how-it-works li {
  position: relative;
  padding: 24px;
  background: #F7FAFC;
  border-radius: 12px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #2D3748;
  transition: all 0.3s ease;
}

.how-it-works li:hover {
  background: var(--primary-blue-light);
  color: white;
  transform: translateY(-3px);
}

.how-it-works li::before {
  counter-increment: steps;
  content: counter(steps);
  position: absolute;
  top: -12px;
  left: -12px;
  width: 36px;
  height: 36px;
  background: var(--accent-yellow);
  color: #2D3748;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: all 0.3s ease;
}

.how-it-works li:hover::before {
  background: white;
  color: var(--primary-blue);
}

.cta-section {
  text-align: center;
  /* background: linear-gradient(135deg, var(--primary-blue) 0%, #f4fcff 100%); */
  /* background-color: var(--primary-blue-light); */
  /* margin: 150px 0 200px 0; */
}

.cta-section h2 {
  color: var(--primary-blue-dark);
}

.cta-button {
  display: inline-block;
  padding: 16px 32px;
  font-size: 1.2rem;
  font-weight: 600;
  color: white;
  background: var(--primary-blue);
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  width: 700px;
}

.cta-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  background: var(--accent-yellow);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.3s ease, height 0.3s ease;
  z-index: -1;
}

.cta-button:hover {
  background-color: #FFD037;
}

.cta-button:hover::before {
  width: 300px;
  height: 300px;
}

@media (max-width: 768px) {
  .hire-with-us-banner {
    height: 400px;
  }

  .hire-banner-subtitle {
    font-size: 2rem;
  }

  section {
    margin: 40px 0;
    padding: 24px;
  }

  h2 {
    font-size: 1.75rem;
  }

  .how-it-works ol {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .hire-with-us-banner {
    height: 300px;
  }

  .hire-banner-subtitle {
    font-size: 1.5rem;
  }

  section {
    margin: 24px 0;
    padding: 20px;
  }

  h2 {
    font-size: 1.5rem;
  }
}


/* BANNER STYLES */
/* Updated Hire with Us Banner Styles */
.hire-with-us-banner {
  position: relative;
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: linear-gradient(to bottom, #5247c8, #7bb5fb, #8cbffd81, white);
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  margin: 0;
}

.hire-sun-effect {
  position: absolute;
  top: 25%;
  right: 25%;
  width: 8rem;
  height: 8rem;
  background-color: #fbbf24;
  border-radius: 50%;
  opacity: 0.75;
  box-shadow: 0 0 60px 30px #fbbf24, 0 0 100px 60px #fbbf24, 0 0 140px 90px #fbbf24;
  transition: all 0.2s ease-out;
}

.hire-banner-image {
  width: 70%;
}

@media (max-width: 768px) {
  .hire-banner-image {
    padding-top: 80px;
    width: 100vw; 
  }

  .cta-button {
    width: 80vw;
  }
}


.hire-banner-content {
  position: relative;
  z-index: 10;
  text-align: center;
  padding: 0 1rem;
}

.hire-banner-subtitle {
  font-size: 1.5rem;
  color: white;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.hire-banner-button {
  background-color: #fbbf24;
  color: #1e40af;
  font-weight: 600;
  padding: 0.75rem 2rem;
  border-radius: 9999px;
  text-decoration: none;
  transition: all 0.3s ease;
  display: inline-block;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.hire-banner-button:hover {
  background-color: white;
  color: #1e40af;
  transform: translateY(-2px) scale(1.05);
}

@media (max-width: 640px) {
  .hire-banner-subtitle {
      font-size: 1.25rem;
  }

  .hire-banner-button {
      padding: 0.5rem 1.5rem;
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 0.6s ease-out forwards;
}

.animation-delay-300 {
  animation-delay: 300ms;
}

.animation-delay-600 {
  animation-delay: 600ms;
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}