.home-banner {
    position: relative;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background: linear-gradient(to bottom, #5247c8, #7bb5fb, #8cbffd81, white);
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

.sun-effect {
    position: absolute;
    top: 25%;
    right: 25%;
    width: 8rem;
    height: 8rem;
    background-color: #fbbf24;
    border-radius: 50%;
    opacity: 0.75;
    box-shadow: 0 0 60px 30px #fbbf24, 0 0 100px 60px #fbbf24, 0 0 140px 90px #fbbf24;
    transition: all 0.2s ease-out;
}

.home-banner-image {
    max-width: 50%;
}

.home-banner-content {
    position: relative;
    z-index: 10;
    text-align: center;
    padding: 0 1rem;
}

.home-banner-subtitle {
    font-size: 1.5rem;
    color: white;
    margin-bottom: 2rem;
    /* max-width: 36rem; */
    margin-left: auto;
    margin-right: auto;
}

.home-banner-button {
    background-color: #fbbf24;
    color: #1e40af;
    font-weight: 600;
    padding: 0.75rem 2rem;
    border-radius: 9999px;
    text-decoration: none;
    transition: all 0.3s ease;
    display: inline-block;
    border: none;
    cursor: pointer;
    font-size: 1rem;
}

.home-banner-button:hover {
    background-color: white;
    color: #1e40af;
    transform: translateY(-2px) scale(1.05);
}

@media (max-width: 640px) {
    .home-banner-subtitle {
        font-size: 1.25rem;
    }

    .home-banner-button {
        padding: 0.5rem 1.5rem;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.animate-fade-in {
    animation: fadeIn 0.6s ease-out forwards;
}

.animation-delay-300 {
    animation-delay: 300ms;
}

.animation-delay-600 {
    animation-delay: 600ms;
}

@keyframes pulse {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
}

.animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}