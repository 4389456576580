/* Reset and base styles */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: white;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: #333;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Navbar Styles */
.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  background-color: transparent;
  z-index: 1000;
}

.navbar-logo h1 {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 2rem;
  list-style: none;
}

.navbar-links a {
  text-decoration: none;
  color: white;
  font-weight: 500;
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: var(--accent-yellow);
}

.hire-link a {
  background-color: var(--accent-yellow);
  color: var(--primary-blue);
  padding: 0.75rem 1.5rem;
  border-radius: 50px;
  transition: all 0.3s ease;
}

.hire-link a:hover {
  background-color: white;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .home-banner {
    height: 400px;
    padding-top: 80px;
    width: 100vw; 
  }

  .banner-subtitle {
    font-size: 1.25rem;
  }

  .banner-description {
    font-size: 1rem;
  }

  .navbar {
    flex-direction: column;
    padding: 1rem;
  }

  .navbar-logo {
    margin-bottom: 1rem;
  }

  .navbar-links {
    flex-direction: column;
    gap: 1rem;
  }

  /* .job-list {
    width: 100vh;
  } */
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-up {
  animation: fadeInUp 0.6s ease-out forwards;
}

.animation-delay-300 {
  animation-delay: 300ms;
}

.animation-delay-600 {
  animation-delay: 600ms;
}

/* Banner Styles */
.banner {
  position: relative;
  height: 600px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
  /* box-shadow: 0 4px 100px #ffc40356; */
}

.banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
      linear-gradient(rgba(255,255,255,0.1) 1px, transparent 1px),
      linear-gradient(90deg, rgba(255,255,255,0.1) 1px, transparent 1px);
  background-size: 20px 20px;
  opacity: 0.3;
  z-index: 1;
  animation: fadeBg 2s ease-out;
}

@keyframes fadeBg {
  from { opacity: 0; }
  to { opacity: 0.3; }
}

.banner-content {
  position: relative;
  z-index: 2;
  max-width: 800px;
}

.banner-image {
  max-width: 60%;
  height: auto;
  margin-bottom: 2rem;
  filter: drop-shadow(0 10px 15px rgba(0, 0, 0, 0.2));
  animation: rise 1.5s ease-out;
}

@keyframes rise {
  0% { transform: translateY(50px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

.banner-subtitle {
  font-size: 2rem;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 2rem;
  letter-spacing: 1px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: fadeIn 1s ease-out 0.5s forwards;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

.banner-cta {
  display: inline-block;
  background-color: #ffd037;
  color: #333;
  padding: 1rem 2rem;
  border-radius: 50px;
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: fadeIn 1s ease-out 1s forwards;
}

.banner-cta:hover {
  background-color: #ffdb69;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.early-bird {
  position: absolute;
  width: 40px;
  height: 40px;
  /* background: url('path-to-your-bird-icon.svg') no-repeat center center; */
  background-size: contain;
  animation: fly 20s linear infinite;
}

@keyframes fly {
  0% { left: -5%; top: 20%; }
  25% { left: 25%; top: 10%; }
  50% { left: 50%; top: 25%; }
  75% { left: 75%; top: 15%; }
  100% { left: 105%; top: 20%; }
}

.morning-mist {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.2), transparent);
  filter: blur(10px);
  animation: mistRise 3s ease-out;
}

@keyframes mistRise {
  from { transform: translateY(100%); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@media (max-width: 768px) {
  .banner {
      height: 500px;
  }

  .banner-image {
      max-width: 80%;
  }

  .banner-subtitle {
      font-size: 1.5rem;
  }

  .morning-mist {
      height: 80px;
  }
}

@media (max-width: 480px) {
  .banner {
      height: 400px;
  }

  .banner-image {
      max-width: 90%;
  }

  .banner-subtitle {
      font-size: 1.2rem;
  }

  .banner-cta {
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
  }

  .morning-mist {
      height: 60px;
  }
}

/* Job Filter Styles */
.job-filter {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 1.5rem;
  align-items: end;
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.filter-group label {
  font-size: 0.9rem;
  color: #666;
  font-weight: 500;
}

.filter-group select {
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  color: #333;
  background-color: white;
  transition: border-color 0.3s ease;
}

.filter-group select:focus {
  outline: none;
}

.apply-filters-btn {
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  height: fit-content;
}

.apply-filters-btn:hover {
  background-color: #ffd037;
  transform: translateY(-2px);
}

.jobs-number {
  margin-bottom: 50px;
}

/* Job List Styles */
.job-list {
  width: 800px;
  margin: 0 auto;
  padding: 0 1rem;
  /* width: 100vw; */
}

.company-logo-container {
  position: absolute;
  top: -15px;
  left: -15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #ffd037d7;
  box-shadow: 0 2px 20px #ffd037d7;
}

.company-logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.job-header {
  margin-left: 2.5rem;
  margin-bottom: 1rem;
}

.job-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: rgba(56, 55, 55, 0.919);
  margin-bottom: 0.5rem;
}

.job-company {
  font-size: 1rem;
  color: #666;
}

.job-info {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.job-location, .job-time {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #666;
  font-size: 0.9rem;
  /* margin-bottom: 5px; */
}

.pin-icon, .clock-icon {
  width: 16px;
  height: 16px;
  fill: currentColor;
  display: inline-block;
  vertical-align: middle;
  object-fit: contain;
}

.pin-icon svg, .clock-icon svg {
  width: 100%;
  height: 100%;
}

.job-apply {
  display: flex;
  justify-content: flex-end;
}

.view-button {
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.3s ease;
}

.view-button:hover {
  background-color: #ffd037;
  transform: translateY(-2px);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.job-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 3.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: all 0.3s ease;
  opacity: 0;
  animation: fadeInUp 0.6s ease-out forwards;
}

.job-card:nth-child(1) { animation-delay: 0.1s; }
.job-card:nth-child(2) { animation-delay: 0.2s; }
.job-card:nth-child(3) { animation-delay: 0.3s; }
.job-card:nth-child(4) { animation-delay: 0.4s; }
.job-card:nth-child(5) { animation-delay: 0.5s; }
/* Continue this pattern for as many cards as needed */

.job-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.load-more-btn {
  display: block;
  margin: 2rem auto;
  background-color: transparent;
  border: 2px solid var(--primary-blue);
  color: var(--primary-blue);
  padding: 0.75rem 2rem;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.load-more-btn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 300px;
  background-color: #ffd037d7;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.5s ease-out;
  z-index: -1;
}

.load-more-btn:hover {
  color: white;
}

.load-more-btn:hover::after {
  transform: translate(-50%, -50%) scale(1);
}

.load-more-btn:active {
  transform: translateY(1px);
}

.no-jobs-message {
  text-align: center;
  color: #666;
  margin: 2rem 0;
  font-size: 1.1rem;
}

/* Hamburger Menu Styles */
.hamburger {
  display: none; /* Hidden by default */
  cursor: pointer;
  flex-direction: column;
  gap: 5px;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar {
      padding: 1rem;
  }

  .banner {
      height: 400px;
  }

  .banner-image {
      max-width: 80%;
  }

  .job-filter {
      grid-template-columns: 1fr;
      gap: 1rem;
  }

  .job-list {
    width: 100vw;
  }

  .job-card {
      padding: 1rem;
  }

  .company-logo-container {
      width: 40px;
      height: 40px;
      top: -10px;
      left: -10px;
  }

  .job-header {
      margin-left: 2rem;
  }

  .navbar-links {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--primary-blue);
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
    display: none; /* Hidden by default */
    align-items: center;
    z-index: 999;
  }

  .navbar-links.show {
    display: flex; /* Shown when toggled */
  }

  .hamburger {
    display: flex;
  }

  .navbar-logo {
    margin-bottom: 1rem;
  }

  .navbar-links a {
    color: white;
  }
}

@media (max-width: 480px) {
  .navbar-links {
      gap: 1rem;
  }

  .banner {
      height: 300px;
  }

  .banner-subtitle {
      font-size: 1.2rem;
  }

  .job-info {
      flex-direction: column;
      gap: 0.5rem;
  }
}

.footer {
  background: linear-gradient(to bottom, white 0%, #ffd037cc 100%);
  color: black;
  padding: 60px 20px 40px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin-top: 100px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 40px;
  position: relative;
}

.footer-brand {
  padding-right: 40px;
}

.footer-logo {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 16px;
  color: black;
  letter-spacing: 1px;
}

.footer-description {
  font-size: 0.95rem;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.9);
  margin: 0;
}

.footer-section {
  display: flex;
  flex-direction: column;
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer-links-title {
  color: rgb(0, 0, 0);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.footer-links a {
  color: rgba(0, 0, 0, 0.9);
  text-decoration: none;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.footer-links a:hover {
  color: #4A90E2;
  transform: translateX(5px);
}

.footer-social {
  display: flex;
  gap: 16px;
  margin-top: 20px;
}

.social-icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.social-icon:hover {
  background: #4A90E2;
  transform: translateY(-3px);
}

.social-icon svg {
  color: rgba(0, 0, 0, 0.8);
  transition: color 0.3s ease;
}


.social-icon:hover svg {
  color: white !important;
}


.footer-bottom {
  grid-column: 1 / -1;
  text-align: center;
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.9);
}

@media (max-width: 1024px) {
  .footer-content {
      grid-template-columns: 1fr 1fr;
  }

  .footer-brand {
      grid-column: 1 / -1;
      padding-right: 0;
      text-align: center;
  }

  .footer-section {
      align-items: center;
  }

  .footer-links {
      align-items: center;
  }
}

@media (max-width: 640px) {
  .footer {
      padding: 40px 20px 30px;
  }

  .footer-content {
      grid-template-columns: 1fr;
      gap: 30px;
  }

  .footer-brand {
      padding-bottom: 20px;
  }

  .footer-section {
      text-align: center;
  }

  .footer-links a:hover {
      transform: none;
  }
}

/* Update the primary blue color */
:root {
  --primary-blue: #60a5fa;
  --primary-blue-dark: #3A7BC8;
  --primary-blue-light: #6AA9E9;
  --accent-yellow: #FFD037;
}

/* Navbar Styles */
.navbar-logo h1 {
  color: white;
}

.navbar-links a:hover {
  color: var(--primary-blue);
}

.hire-link a {
  background-color: var(--primary-blue);
  color: white;
}

.hire-link a:hover {
  background-color: var(--accent-yellow);
}

/* Banner Styles */
.banner {
  background: linear-gradient(135deg, var(--primary-blue) 0%, #f4fcff 100%);
}

/* Job Filter Styles */
.filter-group select:focus {
  border-color: var(--primary-blue);
}

.apply-filters-btn {
  background-color: #60a5fa;
}

.apply-filters-btn:hover {
  background-color: white;
  color: var(--primary-blue-dark);
}

/* Job Card Styles */
.view-button {
  background-color: #60a5fa;
}

.view-button:hover {
  background-color: white;
  color: var(--primary-blue-dark);
}

/* Load More Button */
.load-more-btn {
  border: 2px solid var(--primary-blue);
  color: var(--primary-blue);
}

.load-more-btn:hover {
  background-color: var(--primary-blue);
}

.footer-logo,
.footer-description,
.footer-links-title,
.footer-links a {
  color: rgba(56, 55, 55, 0.879);
}

/* Start mockup testing */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-up {
  animation: fadeInUp 0.6s ease-out forwards;
}

.animation-delay-300 {
  animation-delay: 300ms;
}

.animation-delay-600 {
  animation-delay: 600ms;
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

